/**
 * Example of usage:
 * <span class="js-read-more" data-limit="300" data-limit_mobile="130" data-mobile_only="true">
 *      Content
 * </span>
 */

class ReadMore {
  constructor() {
    this.readMoreButton = '<button class="link-quiet js-read-more-show">read more</button>';
    this.readLessButton = '<button class="link-quiet js-read-more-hide">less</button>';
    this.defaultTextLimit = 100;
  }

  init(el) {
    this.initReadMore(el);
  }

  initReadMore(el, textOriginal) {
    const text = textOriginal || el.innerHTML;
    const dataLimit = el.dataset.limit;
    const dataLimitMobile = el.dataset.limit_mobile;
    const mobileOnly = el.dataset.mobile_only;
    let limit;

    if (window.isMobile) {
      limit = dataLimitMobile || this.defaultTextLimit;
    } else {
      limit = dataLimit || this.defaultTextLimit;
    }

    if (text.length > limit && !(window.isMobile == null && mobileOnly)) {
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = `${text.trim().substring(0, limit).split(' ').slice(0, -1)
        .join(' ')}…${this.readMoreButton}`;

      this.initReadMoreShow(el, text);
    }
  }

  initReadMoreShow(el, text) {
    const readMoreShow = document.querySelectorAll('.js-read-more-show');
    Array.from(readMoreShow).forEach((elShow) => {
      elShow.addEventListener('click', () => {
        const readMoreEl = elShow.closest('.js-read-more');
        readMoreEl.innerHTML = `${text} ${this.readLessButton}`;
        this.initReadMoreHide(el, text);
      });
    });
  }

  initReadMoreHide(el, text) {
    const readMoreHide = document.querySelectorAll('.js-read-more-hide');
    Array.from(readMoreHide).forEach((elShow) => {
      elShow.addEventListener('click', () => {
        this.initReadMore(el, text);
      });
    });
  }
}

const readMoreElements = document.querySelectorAll('.js-read-more');

Array.from(readMoreElements).forEach((el) => {
  const readMore = new ReadMore();

  readMore.init(el);
});
