/**
 * Usage example:
 * a) <div class="js-clamp">Lorem ipsum [...]</div>
 * b) <div class="js-clamp" style="--js-line-clamp: 5">Lorem ipsum [...]</div>
 */

const debounce = require('lodash.debounce');

const clampInit = function () {
  const clampVisible = document.getElementsByClassName('js-clamp--visible');

  for (let i = 0; i < clampVisible.length; i += 1) {
    const clampVisibleItem = clampVisible.item(i);

    clampVisibleItem.classList.remove('js-clamp--visible');
  }

  const clampCurrentHide = document.getElementsByClassName('js-clamp__hide');

  for (let i = 0; i < clampCurrentHide.length; i += 1) {
    const currentHideItem = clampCurrentHide.item(i);

    currentHideItem.remove();
  }

  const clampCurrentShow = document.getElementsByClassName('js-clamp__show');

  for (let i = 0; i < clampCurrentShow.length; i += 1) {
    const currentShowItem = clampCurrentShow.item(i);

    currentShowItem.remove();
  }

  const clampItems = document.getElementsByClassName('js-clamp');

  for (let i = 0; i < clampItems.length; i += 1) {
    const clampItem = clampItems.item(i);
    const { scrollHeight } = clampItem;
    const { clientHeight } = clampItem;

    clampItem.classList.add('js-clamp--overflowed');

    if (scrollHeight > clientHeight) {
      clampItem.innerHTML += '<div class="js-clamp__hide"><a href="#">Show less</a></div>';
      clampItem.innerHTML += '<div class="js-clamp__show">...<a href="#">Read more</a></div>';
    }
  }

  const clampShows = document.getElementsByClassName('js-clamp__show');

  for (let i = 0; i < clampShows.length; i += 1) {
    const clampShow = clampShows.item(i);

    clampShow.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.js-clamp')
        .classList
        .add('js-clamp--visible');
      this.closest('.js-clamp')
        .classList
        .remove('js-clamp--overflowed');
    });
  }

  const clampHides = document.getElementsByClassName('js-clamp__hide');

  for (let i = 0; i < clampHides.length; i += 1) {
    const clampHide = clampHides.item(i);

    clampHide.addEventListener('click', function (e) {
      e.preventDefault();
      this.closest('.js-clamp')
        .classList
        .remove('js-clamp--visible');
      this.closest('.js-clamp')
        .classList
        .add('js-clamp--overflowed');
    });
  }
};

clampInit();

window.addEventListener('resize', debounce(() => {
  clampInit();
}, 200), true);
