export function blockBodyScroll() {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
}

export function unblockBodyScroll() {
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.width = '';
  document.documentElement.style.scrollBehavior = 'auto';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.documentElement.style.scrollBehavior = '';
}
