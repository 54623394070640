import { blockBodyScroll, unblockBodyScroll } from './blockBodyScroll';

/**
 *  Include CSS for modal /Sources/Sass/_occa/4-utilities/modal.scss
 *  Include this script in your webpack config
 *
 *  Usage example:
 *  <a class="js-modal" data-modal-id="modal-1">Open modal</a>
 *
 *  <div class="popup js-modal-window" id="modal-1">
 *     <a class="js-modal-close popup__overlay"></a>
 *     <div class="popup__wrapper">
 *         <a class="js-modal-close popup__close">Close</a>
 *        My Modal Content
 *     </div>
 *  </div>
 */
export function initModal() {
  const modalOpenBtns = document.getElementsByClassName('js-modal');

  for (let i = 0; i < modalOpenBtns.length; i += 1) {
    const modalOpen = modalOpenBtns.item(i);

    modalOpen.addEventListener('click', () => {
      const { modalId } = modalOpen.dataset;
      const modalWindow = document.getElementById(modalId);
      const modalCloseBtns = document.querySelectorAll(`#${modalId} .js-modal-close`);

      if (!modalWindow.classList.contains('active')) {
        modalWindow.classList.add('active');
        blockBodyScroll();
      }

      for (let i = 0; i < modalCloseBtns.length; i += 1) {
        const close = modalCloseBtns.item(i);

        close.addEventListener('click', () => {
          modalWindow.classList.remove('active');
          unblockBodyScroll();
        });
      }
    });
  }
}

(function () {
  initModal();
}());
