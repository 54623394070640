function respPlaceholder() {
  const elements = document.getElementsByClassName('resp-placeholder');

  for (let i = 0; i < elements.length; i += 1) {
    const element = elements.item(i);
    const { placeholderMd } = element.dataset;
    const { placeholder } = element;

    // eslint-disable-next-line no-undef
    element.placeholder = isSmall() ? placeholder : placeholderMd;
  }
}

(function () {
  respPlaceholder();
}());
