const elements = document.getElementsByClassName('js-accordion-header');

(function () {
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].addEventListener('click', (e) => {
      e.preventDefault();
      const element = elements[i];

      const wrapper = element.closest('.js-accordion');
      wrapper.classList.toggle('accordion__expanded');

      const body = wrapper.querySelector('.js-accordion-body');

      if (wrapper.classList.contains('accordion__expanded')) {
        const bodyHeight = body.scrollHeight;
        body.style.maxHeight = `${bodyHeight}px`;
      } else {
        body.style.maxHeight = '0px';
      }
    });
  }
}());

// TODO combine these two solutions
const jsCollapse = 'js-collapse';
const jsCollapseWrap = 'js-collapse-wrap';

document.querySelectorAll(`.${jsCollapse}`).forEach((item) => {
  item.addEventListener('click', (e) => {
    e.preventDefault();
    item.closest(`.${jsCollapseWrap}`).classList.toggle('active');
  });
});
